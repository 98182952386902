"use client";

import { init, tx, id } from "@instantdb/react";
import { useEffect, useState } from "react";
import { formatDate, formatDateLong, formatTime } from "./utils";
import { Backspace, CheckCircle } from "@phosphor-icons/react";
import {
  LowerHalf,
  SidebarContainer,
  SidebarItem,
  SidebarItemContent,
  NotesContainer,
  Container,
  SidebarItemTimestampContainer,
  Input,
  NoteContainer,
  Timestamp,
  ButtonContainer,
  Textarea,
  TagContainer,
  Tag,
} from "./styles";
import DateTimePicker from "./DateTimePicker";
import MarkdownEditor from "./MardownEditor";

const APP_ID = "88c74f08-ba4e-4ea8-9158-0d77feb9a6ef";
const db = init({ appId: APP_ID, devtool: false });

const TagInput = ({ tags, setTags }) => {
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (e) => setInputValue(e.target.value);
  const handleKeyDown = (e) => {
    if (e.key === " " || e.key === "Enter" || e.key === "Tab") {
      const newTags = inputValue.trim().split(/\s+/);
      if (newTags.length > 0) {
        setTags([...tags, ...newTags]);
        setInputValue("");
      }
    }
  };

  return (
    <TagContainer>
      {tags.map((tag) => (
        <Tag onClick={() => setTags(tags.filter((t) => t !== tag))}>{tag}</Tag>
      ))}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        style={{
          border: "none",
          outline: "none",
          fontFamily: "JetBrains Mono",
          backgroundColor: '#000',
          color: '#fff'
        }}
      />
    </TagContainer>
  );
};

const Note = ({ note, updateNote, deleteNote }) => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [tags, setTags] = useState([]);

  const [timestamp, setTimestamp] = useState();
  const [isUpdatingTimestamp, setIsUpdatingTimestamp] = useState(false);

  useEffect(() => {
    const now = Date.now();
    setTitle(note?.title ?? formatDateLong(note?.createdAt ?? now));
    setText(note?.text ?? "New note");
    setTags(note?.tags ?? []);
    setTimestamp(note?.createdAt ?? now);

    return () => {
      // if (!note) return;
      // console.log("saving current note", note);
      // updateNote(
      //   {
      //     ...note,
      //     title: title ?? formatDateLong(timestamp ?? Date.now()),
      //     text,
      //     tags,
      //     createdAt: timestamp,
      //   },
      //   false
      // );
    };
  }, [note]);

  return (
    <NoteContainer>
      <Input
        value={title}
        onChange={(evt) => {
          evt.preventDefault();
          setTitle(evt.target.value);
        }}
        type="text"
      />
      <Timestamp>
        {isUpdatingTimestamp ? (
          <DateTimePicker
            onSubmit={(date, time) => {
              setTimestamp(new Date(`${date}T${time}`));
              setIsUpdatingTimestamp(false);
            }}
          />
        ) : (
          <div onClick={() => setIsUpdatingTimestamp(true)}>
            {formatDateLong(timestamp ?? Date.now())} |{" "}
            {formatTime(timestamp ?? Date.now())}
          </div>
        )}
      </Timestamp>
      <ButtonContainer>
        <CheckCircle
          style={{ cursor: "pointer"}}
          color="#aaaaff"
          size={16}
          onClick={() => {
            updateNote(
              note
                ? {
                    ...note,
                    title: title ?? formatDateLong(timestamp ?? Date.now()),
                    text,
                    tags,
                    createdAt: timestamp,
                  }
                : {
                    id: id(),
                    title,
                    text,
                    done: null,
                    createdAt: Date.now(),
                    tags,
                  }
            );
          }}
        />
        <Backspace
          size={16}
          style={{ paddingLeft: "10px", cursor: "pointer" }}
          color="#ffaaaa"
          onClick={() => {
            note && deleteNote(note.id);
          }}
        />
      </ButtonContainer>
      <TagInput tags={tags} setTags={setTags} />
      <Textarea
        autoFocus
        type="text"
        value={text}
        rows={40}
        onChange={(e) => {
          e.preventDefault();
          setText(e.target.value);
        }}
      />
    </NoteContainer>
  );
};

const Sidebar = ({ logs, note, setNote }) => {
  return (
    <SidebarContainer>
      {logs.map((log, key) => (
        <SidebarItem
          onClick={() => setNote(note?.createdAt == log.createdAt ? null : log)}
          key={key}
        >
          <SidebarItemContent current={note} log={log}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{log?.title}</span>
              <SidebarItemTimestampContainer>
                <span>{formatDateLong(log?.createdAt ?? Date.now())}</span>
                <span>{formatTime(log?.createdAt ?? Date.now())}</span>
              </SidebarItemTimestampContainer>
            </div>
          </SidebarItemContent>
        </SidebarItem>
      ))}
    </SidebarContainer>
  );
};

const AccessPanel = () => {
  const [note, setNote] = useState(null);
  const [events, setEvents] = useState([]);

  const { isLoading, error, data } = db.useQuery({ logs: {} });
  if (isLoading) return <div>Fetching data...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;

  const { logs } = data;

  const updateNote = (n) => {
    db.transact(tx.logs[n.id].update(n));
    setNote(n);
  };

  const deleteNote = (id) => {
    const confirm = window.confirm(`Delete ${note.title}?`);
    if (confirm) {
      db.transact(tx.logs[id].delete());
      setNote(null);
    }
  };

  return (
    <Container>
      <NotesContainer>
        <Sidebar logs={logs} note={note} setNote={setNote} />
        <Note note={note} updateNote={updateNote} deleteNote={deleteNote} />
      </NotesContainer>
      {/* <LowerHalf> */}
      {/* <div style={{ backgroundColor: "#FFF12322" }}></div> */}
      {/* <div style={{ backgroundColor: "#123FFF22" }}></div> */}
      {/* </LowerHalf> */}
    </Container>
  );
};

export default AccessPanel;
