import React, { useState } from "react";

const DateTimePicker = ({ onSubmit }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(selectedDate, selectedTime);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="date">Select a date:</label>
        <input
          type="date"
          id="date"
          value={selectedDate}
          onChange={handleDateChange}
        />

        <label htmlFor="time">Select a time:</label>
        <input
          type="time"
          id="time"
          value={selectedTime}
          onChange={handleTimeChange}
        />

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default DateTimePicker;
