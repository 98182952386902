import styled from "styled-components";

export const SidebarContainer = styled.ul`
  box-sizing: border-box;
  list-style: none;
`;

export const SidebarItem = styled.li`
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box;
`;

export const SidebarItemContent = styled.div`
  box-sizing: border-box;
  // border-radius: 4px;
  padding: 4px 8px;
  border-left: ${(props) =>
    props.current?.createdAt == props.log.createdAt
      ? "3px solid #FE6847"
      : "none"};
  &:hover {
    background-color: #000;
    color: #fff;
  }
`;

export const NotesContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 10fr;
`;

export const Container = styled.div`
  display: grid;
  font-family: JetBrains Mono;
  grid-template-rows: 1fr 1fr;
  height: 100vh;
  background-color: #000;
  color: #fff;
`;

export const SidebarItemTimestampContainer = styled.div`
  margin-top: 2px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
`;

export const LowerHalf = styled.div`
  // display: grid;
  // grid-template-columns: 1fr 1fr;
`;

export const Input = styled.input`
  border: none;
  outline: none;
  font-family: JetBrains Mono;
  font-size: 20px;
  background-color: #000;
  color: #fff;
`;

export const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const Timestamp = styled.span`
  font-size: 12px;
  margin-top: 6px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export const Textarea = styled.textarea`
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  outline: none;
  resize: none;
  background-color: #000;
  color: #fff;
`;

export const TagContainer = styled.div`
  font-size: 12px;
  flex-direction: column;
  margin-top: 10px;
`;

export const Tag = styled.span`
  background-color: #2176ae;
  color: #fff;
  cursor: pointer;
  padding: 4px 6px;
  margin-bottom: 10px;
  border-radius: 2px;
  margin-right: 6px;
`;
